import React from 'react'
import LayoutSimple from '../components/layout-simple'
import HeaderContact from '../components/header-contact/header-contact'
import FormContact from '../components/form-contact/form-contact'
import SEO from "../components/seo"

const ContactPage = (props) => {
  const {messages} = props.pageContext;
  return(
    <LayoutSimple> 
      <SEO 
        title="Happy Selfie" 
        description="El fotomatón autónomo para tu evento"
        link= {
          [
            {
              rel: "canonical",
              href: "https://happyselfie.es/contacto/"
            },
            {
              rel: "alternate",
              href: "https://happyselfie.es/contacto/",
              hreflang: 'es-ES'
            },
            {
              rel: "alternate",
              href: "https://happyselfie.es/eu/kontaktua/",
              hreflang: 'eu-ES'
            },
            {
              rel: "alternate",
              href: "https://happyselfie.es/en/contact/",
              hreflang: 'en-US'
            }
          ]
        }
      />
      <HeaderContact lang={props.pageContext.lang} messages={messages} ></HeaderContact>
      <FormContact messages={messages}/>
    </LayoutSimple>
  )
}

export default ContactPage