import React from 'react'
import './form-fields.scss'

const CheckBox = ({label, name, disabled = false}) => {
  return (
    <div className="field-wrapper checkbox">
      <input type="checkbox" name={name} disabled={disabled}/>
      <label>{label}</label>
    </div>
  )
}


const Input = ({type, label, placeholder, name, disabled = false}) => {
  return (
    <div className="field-wrapper input">
      <label>{label}</label>
      <input type={type} placeholder={placeholder} name={name} disabled={disabled}/>
    </div>
  )
}


class Radio extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected : this.props.options[0].value
    }
  }


  selectRadio(e) {
    this.setState({...this.state, selected: e.target.getAttribute('value')})
  }

  render() {
    return (
      <>
        <div className="field-wrapper radio">
          <label>{this.props.label}</label>
          <div>
            <input type="text" hidden name={this.props.name} value={this.state.selected} onChange={this.selectRadio.bind(this)}/>
            {this.props.options.map((o, key) => {
              return (
                <button
                  className={ this.state.selected !== o.value ? 'unselected': ''} 
                  type="button" 
                  key={key} 
                  value={o.value}
                  onClick={this.selectRadio.bind(this)}>
                  {o.text}
                </button>
              )
            })}
          </div>
        </div>
      </>
    )
  }
}

const TextArea = ({label, placeholder, name, disabled = false}) => {
  return (
    <div className="field-wrapper textarea">
      <label>{label}</label>
      <textarea placeholder={placeholder} name={name} disabled={disabled}>
      </textarea> 
    </div>
  )
}

export {Input, CheckBox, Radio, TextArea}