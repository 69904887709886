import React from 'react'
import './header-contact.scss'
import CloseSVG from './../../assets/icons/close.svg'
import {Link} from 'gatsby'
import {t} from '../../utils/utils'

const HeaderContact = ({lang, messages}) => {
  return (
    <>
      <header className="header-contact">
        <h2>{t('contacto-title', messages)}</h2>
        <button className="close" type="button"><Link to={ lang === "eu" ? "/eu" : "/"}><CloseSVG /></Link></button>
      </header>
    </>
  )
}

export default HeaderContact