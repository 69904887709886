import React, {useState} from "react"
import {Input, Radio, CheckBox, TextArea} from './../form-fields/form-fields'
import Row from "../../components/grid/row"
import Col from "../../components/grid/col"
// import Input from "../form-fields/input"
// import Radio from "../form-fields/radio"
// import CheckBox from "../form-fields/checkbox"
// import TextArea from "../form-fields/textarea"
import './form-contact.scss'
import {t} from '../../utils/utils'

const FormContact = ({messages}) => {

  const [stateFormContact, setStateFormContact] = useState({
    sending: false
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    setStateFormContact({sending: true});
    const formData = new FormData(e.target);
    var formDataObject = {};
    formData.forEach(function(value, key){
      formDataObject[key] = value;
    });
    var jsonData = JSON.stringify(formDataObject);
    fetch('https://happyselfie.es/email', {
      method: 'POST',
      body: jsonData,
      headers:{
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setStateFormContact({sending: false});
      e.target.reset();
    })
  }
  
  const sendingClass = stateFormContact.sending ? 'sending': '';
  return(
    <>
      <section className={`form-contact ${sendingClass}`}>
        <form onSubmit={handleSubmit}>
          <p className="form-section-title">{t('contacto-sobre', messages)}</p>
          <Row>
            <Col>
              <Radio
                name="client-type" 
                label={t('contacto-soy', messages)} 
                options={[
                  {text: t('contacto-soy-particular', messages), value: 'Particular'},
                  {text: t('contacto-soy-empresa', messages), value: 'Empresa'}
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input label={t('contacto-nombre', messages)}  type="text" name="name" placeholder={t('contacto-nombre-placeholder', messages)} disabled={stateFormContact.sending} />
              <Input label={t('contacto-email', messages)}  type="email" name="email" placeholder={t('contacto-email-placeholder', messages)}  disabled={stateFormContact.sending}/>
              <Input label={t('contacto-tel', messages)}  type="text" name="phone" placeholder={t('contacto-tel-placeholder', messages)}  disabled={stateFormContact.sending}/>      
            </Col>
          </Row>
          <p className="form-section-title">{t('contacto-evento', messages)}</p>
          <Row>
            <Col>
              <Input label={t('contacto-lugar', messages)} type="text" name="location" placeholder={t('contacto-lugar-placeholder', messages)} disabled={stateFormContact.sending}/>
              <Input label={t('contacto-fecha', messages)} type="text" name="date" placeholder={t('contacto-fecha-placeholder', messages)} disabled={stateFormContact.sending}/>
              <Input label={t('contacto-hora', messages)} type="text" name="time" placeholder={t('contacto-hora-placeholder', messages)} disabled={stateFormContact.sending}/>      
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea placeholder={t('contacto-pensando-placeholder', messages)} label={t('contacto-pensando', messages)} name="message" disabled={stateFormContact.sending}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckBox name="conditions" label={t('contacto-condiciones', messages)}/>
            </Col>
          </Row>
          <button type="submit">{t('contacto-enviar', messages)}</button>
        </form>
      </section>
    </>
  )
}


export default FormContact
